import * as React from 'react'

import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import * as ROUTES from '../../constants/routes'

export default function PageJumbotron() {
  return (
    <div className="container-fluid content">
      <div style={{ display: 'grid' }}>
        <StaticImage
          style={{
            gridArea: '1/1',
            maxHeight: 600,
          }}
          layout="fullWidth"
          aspectRatio={3 / 1}
          alt=""
          src={'../../../static/img/about-jumbotron.jpg'}
          formats={['auto', 'webp', 'avif']}
        />
        <div
          style={{
            gridArea: '1/1',
            position: 'relative',
            placeItems: 'center',
            display: 'grid',
          }}
        >
          <h1 className="is-uppercase has-text-white">
            Sign Up Today To Enjoy All
            <br />
            The Benefits Of The 99Sense Platform
          </h1>
          <p>
            <Link className="button is-info is-rounded" to={ROUTES.SIGN_UP}>
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}
