import * as React from 'react'

import { navigate } from 'gatsby-link'
import ReCAPTCHA from 'react-google-recaptcha'

import Layout from '../../components/Layout'
import PageHeader from '../../components/PageHeader'
import PageJumbotron from '../../components/PageJumbotron'

import telephone from '../../../static/img/telephone.svg'
import email from '../../../static/img/email.svg'
import location from '../../../static/img/location.svg'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <PageHeader
          title={'Get In Touch'}
          subheading={'It starts right here... at home!'}
        />
        <section className="section">
          <div className="container">
            <div className="content">
              <div className="columns">
                <div className="column">
                  <h2 className="title is-size-2 is-uppercase has-text-weight-bold has-text-primary-dark">
                    Contact Us
                  </h2>
                  <div className="card">
                    <div className="card-content">
                      <form
                        name="contact"
                        method="post"
                        action="/contact/thanks/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={this.handleSubmit}
                      >
                        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                        <input type="hidden" name="form-name" value="contact" />
                        <div hidden>
                          <label>
                            Don’t fill this out:{' '}
                            <input
                              name="bot-field"
                              onChange={this.handleChange}
                            />
                          </label>
                        </div>
                        <div className="field">
                          <label className="label" htmlFor={'name'}>
                            Your name
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'text'}
                              name={'name'}
                              onChange={this.handleChange}
                              id={'name'}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label" htmlFor={'email'}>
                            Email
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'email'}
                              name={'email'}
                              onChange={this.handleChange}
                              id={'email'}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label" htmlFor={'contact'}>
                            Contact
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'contact'}
                              name={'contact'}
                              onChange={this.handleChange}
                              id={'contact'}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label" htmlFor={'message'}>
                            Message
                          </label>
                          <div className="control">
                            <textarea
                              className="textarea"
                              name={'message'}
                              onChange={this.handleChange}
                              id={'message'}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <ReCAPTCHA
                            sitekey={process.env.GATSBY_RECAPTCHA_V2_SITE_KEY}
                            onChange={this.onVerify}
                            onExpired={this.onExpired}
                          />
                        </div>
                        <div className="field">
                          <button
                            className="button is-link is-rounded"
                            type="submit"
                          >
                            Send
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <ul className="menu-list">
                    <li>
                      <img src={telephone} alt="Telephone" width="30px" />
                      0318271777
                    </li>
                    <li>
                      <a
                        href="mailto:admin@99sense.co.za"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={email} alt="Email" width="30px" />
                        admin@99sense.co.za
                      </a>
                    </li>
                    <li>
                      <img src={location} alt="Location" width="30px" />
                      27 Bishops Road, Windermere, Durban 4001
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PageJumbotron />
      </Layout>
    )
  }
}
