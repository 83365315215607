import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Globe from '../../../static/vid/globe.mp4'

export default function PageHeader(props) {
  const { height = 400, title, subheading } = props

  return (
    <Fragment>
      <video autoPlay muted loop style={{ height: height }}>
        <source src={Globe} type="video/mp4" />
        Your browser does not support the video tag. We suggest you upgrade your
        browser.
      </video>
      <div className="container video-overlay-text">
        <h1 className="title is-size-2 is-uppercase has-text-weight-bold has-text-white">
          {title}
        </h1>
        <h2 className="subtitle has-text-weight-bold has-text-white">
          {subheading}
        </h2>
      </div>
    </Fragment>
  )
}

PageHeader.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  subheading: PropTypes.string,
}
